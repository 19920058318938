<template>
    <el-scrollbar class="main-container">
        <div class="main-wrapper">
            <div class="coupons">
                <div class="good-cover">
                    <img src="https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg" alt="">
                </div>
                <div class="coupons-content">
                    <div class="store-info">
                        <img src="https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg" alt="">
                        <span class="name">瑞来春家具城</span>
                    </div>
                    <div class="goods-name">
                        <span class="free-shipping">包邮</span>
                        <span class="name">铁艺吧台椅酒吧吧台凳北欧台椅酒吧吧台凳北欧台椅酒吧吧台凳北欧凳北欧凳</span>
                    </div>
                    <div class="goods-price">
                        <span class="price">￥现价469</span>
                        <span class="orange">158笔成交</span>
                    </div>
                    <div class="coupon-list">
                        <div class="coupon-item" v-for="n in 7">
                            <div class="coupon-info">
                                <div class="discount">
                                    <span class="buy">买1件</span>
                                    <span class="save-money">每件省5元</span>
                                </div>
                                <div class="after-coupon">
                                    用券后￥<span class="after-coupon-price">469.0</span>/件
                                </div>
                                <div class="time">使用期限2021.02.21-2021.02.28</div>
                            </div>
                            <div class="coupon-price" @click="getCoupon">
                                <div class="num">￥50</div>
                                <span class="get-it-now">立即领取</span>
                            </div>
                            <div class="circle1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="more-recommend-goods">
                <div class="title">
                    <span class="text">更多宝贝推荐</span>
                </div>
                <div class="list-container">
                    <div class="list-content">
                        <div class="good-item" v-for="(goodItem, goodIndex) in recommendGoodsList" :key="`goodsList_${goodIndex}`">
                            <div class="good-rank">
                                <i class="iconfont">&#xe680;</i>
                                <div class="top">优惠券</div>
                                <div class="price">
                                    ￥
                                    <span>{{goodItem.coupon}}</span>
                                </div>
                            </div>
                            <div class="good-cover">
                                <img :src="goodItem.goodCover" alt="">
                            </div>
                            <div class="good-detail">
                                <a :href="goodItem.goodLink" target="_blank" class="good-name text-overflow-2">{{goodItem.goodName}}</a>
                                <div class="good-sale">
                                    <div class="original-price">{{goodItem.price}}</div>
                                    <span class="orange">{{goodItem.sales}}笔成交</span>
                                </div>
                                <div class="after-coupons">
                                    用券后
                                    <span>￥{{goodItem.afterCoupons}}</span>
                                </div>
                                <div class="collect-coupons">
                                    <div class="coupon-num">优惠券￥{{goodItem.coupon}}</div>
                                    <div class="collect" @click="getCoupon">领取</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="dialogCoupon" class="coupon-dialog">
                <div class="coupon-box">
                    <div class="coupon-bg"></div>
                    <div class="coupon">
                        <div class="amount-of-money">
                            <i class="iconfont">&#xe6b5;</i>
                            <div class="money">￥150</div>
                        </div>
                        <div class="jump">
                            <span>领取成功</span>
                            <span>正在跳转到详情页</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "CollectCoupons",
        data() {
            return {
                //更多宝贝推荐
                recommendGoodsList: [
                    {
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },{
                        id: 1,
                        coupon: 800,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 5000.00,
                        afterCoupons: 4200
                    },
                ],
                //优惠券领取成功弹窗
                dialogCoupon: false
            }
        },
        methods: {
            //领取优惠券
            getCoupon() {
                this.dialogCoupon = true;
                let _this = this;
                setTimeout(function () {
                    _this.goTo();
                }, 1000)
            },
            //跳转
            goTo() {
                this.dialogCoupon = false;
                let routeUrl = this.$router.resolve({
                    path: '/student/productPromotion/index',
                });
                window.open(routeUrl.href, '_blank');
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-container {
        position: relative;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .main-wrapper {
            height: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .coupons {
                display: flex;
                width: 1021px;
                min-height: 384px;
                background-color: #fff;
                box-shadow: 0 0 5px 0 rgba(153, 153, 153, 0.34);
                border-radius: 10px;
                margin-top: 4px;
                padding: 30px;
                box-sizing: border-box;
                .good-cover {
                    width: 326px;
                    height: 323px;
                    margin-right: 30px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .coupons-content {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    width: 1%;
                    .store-info {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 20px;
                        img {
                            width: 33px;
                            height: 33px;
                            margin-right: 10px;
                        }
                        .name {
                            color: #222222;
                            font-size: 16px;
                        }
                    }
                    .goods-name {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 20px;
                        margin-bottom: 20px;
                        .free-shipping {
                            display: block;
                            width: 33px;
                            height: 18px;
                            line-height: 18px;
                            border: 1px solid #FEA100;
                            border-radius: 2px;
                            color: #FEA100;
                            font-size: 10px;
                            text-align: center;
                            margin-right: 10px;
                        }
                        .name {
                            display: block;
                            width: 1%;
                            flex: 1;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .goods-price {
                        display: flex;
                        justify-content: space-between;
                        color: #666;
                        font-size: 14px;
                        margin-bottom: 20px;
                    }
                    .coupon-list {
                        display: flex;
                        flex-wrap: wrap;
                        .coupon-item {
                            position: relative;
                            display: flex;
                            width: 298px;
                            height: 87px;
                            background: #FFEEE4;
                            border-radius: 20px;
                            margin-bottom: 15px;
                            &:nth-child(2n-1) {
                                margin-right: 9px;
                            }
                            .coupon-info {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                flex: 1;
                                width: 1%;
                                padding: 7px 7px 7px 15px;
                                .discount {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 120px;
                                    height: 22px;
                                    border: 1px solid #FF4400;
                                    background-color: #FF4400;
                                    color: #fff;
                                    font-size: 14px;
                                    border-radius: 12px;
                                    .buy {
                                        width: 45px;
                                        text-align: center;
                                    }
                                    .save-money {
                                        width: 75px;
                                        background-color: #fff;
                                        color: #FF4400;
                                        font-weight: 500;
                                        text-align: center;
                                        border-radius: 0 12px 12px 0;
                                    }
                                }
                                .after-coupon {
                                    color: #333;
                                    font-size: 14px;
                                    .after-coupon-price {
                                        font-size: 20px;
                                    }
                                }
                                .time {
                                    color: #999;
                                    font-size: 12px;
                                }
                            }
                            .coupon-price {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                width: 98px;
                                height: 100%;
                                background: linear-gradient(137deg, #FC3A40, #FE8059);
                                border-radius: 0 20px 20px 0;
                                cursor: pointer;
                                color: #fff;
                                .num {
                                    font-size: 24px;
                                    font-weight: 800;
                                }
                                .get-it-now {
                                    font-size: 14px;
                                }
                            }
                            .circle1 {
                                position: absolute;
                                z-index: 5;
                                top: -4px;
                                left: 200px;
                                transform-origin: center left;
                                transform: rotate(90deg);
                                width: 87px;
                                height: 9px;
                                background-image: radial-gradient(circle, #FFEEE4, #FFEEE4 4px, transparent 0px, transparent 2px, transparent 22px, transparent);
                                background-size: 10px 10px;
                                background-repeat: repeat-x;
                            }
                        }
                    }
                }
            }
            .more-recommend-goods {
                width: 100%;
                height: 1%;
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                .title {
                    text-align: center;
                    margin-bottom: 33px;
                    .text {
                        position: relative;
                        color: #666;
                        font-size: 18px;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 28px;
                            left: 13px;
                            width: 80px;
                            height: 4px;
                            background: #FF5200;
                            border-radius: 2px;
                        }
                    }
                }
                .list-container {
                    flex: 1;
                    height: 1%;
                    width: 98%;
                    background-color: #fff;
                    box-shadow: 0 0 5px 0 rgba(153, 153, 153, 0.34);
                    border-radius: 10px;
                    margin: 0 auto;
                    box-sizing: border-box;
                    .list-content {
                        padding: 10px 100px 16px 50px;
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .good-item {
                        width: calc(16.666666% - 50px);
                        margin: 30px 0 0 50px;
                        position: relative;
                        .good-rank {
                            position: absolute;
                            top: -7px;
                            left: 10px;
                            z-index: 1;
                            color: #fff;
                            display: block;
                            .iconfont {
                                font-size: 62px;
                                color: #FE8059;
                            }
                            .top {
                                position: absolute;
                                top: 6px;
                                width: 54px;
                                text-align: center;
                                font-size: 12px;
                                font-weight: 400;
                            }
                            .price {
                                position: absolute;
                                top: 26px;
                                width: 54px;
                                text-align: center;
                                font-size: 12px;
                                font-weight: 400;
                                span {
                                    font-size: 16px;
                                }
                            }
                        }
                        .good-cover {
                            width: 100%;
                            height: 0;
                            padding-bottom: 100%;
                            position: relative;
                            overflow: hidden;
                            background: #EFF0FF;
                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .good-detail {
                            padding: 12px 7px;
                            box-shadow: 0 0 5px 0 rgba(153, 153, 153, 0.34);
                            border-radius: 0 0 10px 10px;
                        }
                        .good-name {
                            color: #222;
                            transition: all .3s;
                            &:hover {
                                color: #FE4800;
                            }
                        }
                        .good-sale {
                            display: flex;
                            justify-content: space-between;
                            margin: 25px 0;
                            color: #666;
                            font-size: 12px;
                            text-align: center;
                            .original-price {
                                text-decoration: line-through;
                            }
                        }
                        .after-coupons {
                            font-size: 14px;
                            span {
                                color: #FE4800;
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                        .collect-coupons {
                            display: flex;
                            color: #EC6941;
                            font-size: 14px;
                            font-weight: 500;
                            .coupon-num {
                                width: 116px;
                                height: 24px;
                                line-height: 24px;
                                border: 1px solid #EC6941;
                                border-radius: 4px;
                                text-align: center;
                            }
                            .collect {
                                width: 64px;
                                height: 26px;
                                line-height: 26px;
                                background: #FF5200;
                                border-radius: 4px;
                                color: #fff;
                                text-align: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    .coupon-dialog {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        z-index: 9;
        .coupon-box {
            position: relative;
            width: 318px;
            height: 235px;
            margin: 200px auto;
            .coupon-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                opacity: 0.8;
                border-radius: 6px;
            }
            .coupon {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                .amount-of-money {
                    width: 190px;
                    i {
                        color: #fff;
                        font-size: 80px;
                    }
                    .money {
                        position: absolute;
                        top: 59px;
                        width: 114px;
                        height: 58px;
                        line-height: 58px;
                        color: #fff;
                        font-size: 24px;
                        font-weight: 800;
                        text-align: center;
                    }
                }
                .jump {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #fff;
                    font-size: 16px;
                    margin-top: 10px;
                }

            }
        }
    }
</style>